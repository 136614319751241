<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="手机端" name="1">
        <div class="card-box">
          <div class="phone">
            <div class="form-title">{{ tableName }}</div>
            <v-form-render
              v-if="formJson.widgetList.length"
              :form-json="formJson"
              :form-data="formData"
              :option-data="optionData"
              ref="vFormRef"
            ></v-form-render>
            <el-button type="primary">提交</el-button>
          </div>
          <!-- <div class="qrcode-box">
            <div class="qrcode" id="qrCode" ref="qrCodeDiv"></div>
            <div class="title">扫码在手机端查看</div>
          </div>-->
        </div>
      </el-tab-pane>
      <el-tab-pane label="电脑端" name="2">
        <div class="phone computer">
          <div class="form-title">{{ tableName }}</div>
          <v-form-render
            v-if="formJson.widgetList.length"
            :form-json="formJson"
            :form-data="formData"
            :option-data="optionData"
            ref="vFormRef"
          ></v-form-render>
          <el-button type="primary">提交</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      activeName: "1",
      id: "",
      tableName: "",
      /* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
      formJson: {
        widgetList: [],
        formConfig: {},
      },
      formData: {},
      optionData: {},
      url: "123",
    };
  },
  mounted() {
    // this.bindQRCode();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      var that = this;
      const { data } = await this.$httpAes({
        url: that.$httpAes.adornUrl("/vfTableInfo/info"),
       method: "post",
       data: {
         stringParam1: this.id,
       },
       contentType: 'json',
       headers: {
       	'Content-Type': 'application/json',
       			
       },
      });
      if (data.status) {
        this.tableName = data.data.tableName;
        if (data.data && data.data.tableContent) {
          this.formJson = JSON.parse(data.data.tableContent);

          console.log("123", this.formJson);
        }
      } else {
        that.$message({
          message: data.data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    handleClick() {},
    submitForm() {
      this.$refs.vFormRef
        .getFormData()
        .then((formData) => {
          // Form Validation OK
          alert(JSON.stringify(formData));
        })
        .catch((error) => {
          // Form Validation failed
          this.$message.error(error);
        });
    },
    bindQRCode() {
      //获取二维码
      let that = this;
      that.qrcode = new QRCode("qrCode", {
        text: that.url,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-box {
  position: absolute;
  bottom: 0;
  right: 0;
  .title {
    text-align: center;
    padding-top: 10px;
  }
}
.form-title {
  font-size: 20px;
  color: #0476F5;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px 0 40px;
}
.phone {
  width: 375px;
  min-height: 667px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  /deep/.el-radio__label {
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
  }

  &.computer {
    width: 70%;
  }
  .el-button {
    margin-top: 50px;
    width: 100%;
  }
}
</style>
